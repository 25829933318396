<template>
  <div></div>
</template>
<script>
import appApi from '@/api/modules/app.api.js';

import logging from '@/utils/logging';
import { getParam } from '@/utils/http-tools';
import { setToken } from '@/utils/auth';
export default {
  name: 'Login',
  created() {
    logging(true);
    // 获取session的值
    console.log(getParam('session'));

    if (getParam('session')) {
      // 有session，凭借此值去获取token
      this.getToken();
    } else {
      console.log(location.origin);
      // 否则跳转到loginCallback这个指定登录中转路由
      const url = new URL(location.origin + '#/loginCallback');
      // 添加from参数，保留其对应的初始跳转路由
      url.searchParams.append('from', this.$route.query.from);
      // 转码URL地址
      let target = encodeURIComponent(url.toString());
      console.log(target);
      // 将处理好的url设置为重定向页面，使其在登录后可以保留url及其业务用参数，并跳转至该url
      // const REDIRECT = location.href.split('#')[0].slice(0, location.href.split('#')[0].length - 1);
      window.location.href = `${process.env.VUE_APP_LOGIN_URL}/login?redirect=${target}`;
      // window.location.href = `https://identify.q2janus.com/login?redirect=${REDIRECT}`;
    }
  },
  methods: {
    async getToken() {
      let [data] = await appApi.getTradeToken({ session: getParam('session') });
      if (!data) return;
      setToken(data);
      await this.$store.dispatch('user/login');
      // window.location.replace(location.origin + location.pathname);
      logging(false);
      this.$router.push({ path: './' });
    },
  },
};
</script>
<style lang="scss" scoped></style>
