/*
 * @Description:
 * @Author: zby
 * @Date: 2021-11-30 14:13:03
 * @LastEditors: zby
 * @Reference:
 */
import { BaseService } from '@/api/request';

const api = {};

/**
 * @description: 获取令牌
 * @param  {*session 	会话id}
 * @return {*data 	令牌}
 */
api.getTradeToken = function (params = {}) {
  return BaseService.postRequest('api/pmf/v1/idenfity/tradeToken', params);
};

export default api;
